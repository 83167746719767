import React from 'react';
import Img from 'gatsby-image';

const LoadingScreen = ({ loading }) => (
	<>
		<section className="bg-primary" style={{ minHeight: '15vh' }} />
		<section className="pt-0" style={{ minHeight: '65vh' }}>
			<div className="container">
				<div className="card">
					<div className="card-body">
						<div className="row py-sm-5">
							<div className="col-12 col-md-10 offset-md-1 text-center">
								<h4 className="mb-3">Egy kis türelmet kérünk</h4>
								<h5 className="font-weight-normal">Az oldal töltődik...</h5>
								<Img
									fluid={loading.childImageSharp.fluid}
									style={{
										maxWidth: 350,
										width: '70%',
										marginLeft: 'auto',
										marginRight: 'auto',
									}}
									alt="Az oldal töltődik..."
									className="my-5"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
);

export default LoadingScreen;
