import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import qs from 'query-string';
import axios from 'axios';
import LoadingScreen from '../components/layout/LoadingScreen';

function verifyEmail(token) {
	return axios.get(
		`/.netlify/functions/verify-email?${qs.stringify({ token })}`
	);
}

export default function VerifyEmailPage({ data, location }) {
	const { token } = qs.parse(location.search);
	const { loading } = data;

	useEffect(() => {
		const handleVerification = async () => {
			try {
				const { data } = await verifyEmail(token);

				const isAlreadyVerified = 'email' in data;
				if (isAlreadyVerified) {
					navigate('/error', { state: { type: 'emailAlreadyVerified' } });
					return;
				}

				navigate('/success', { state: { type: 'verifyEmail' } });
				return;
			} catch (e) {
				navigate('/error');
			}
		};

		if (!token || token.length !== 6) {
			navigate('/error');
		} else {
			handleVerification();
		}
	}, []);

	return <LoadingScreen loading={loading} />;
}

VerifyEmailPage.propTypes = {
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
};

export const query = graphql`
	{
		loading: file(relativePath: { eq: "loading.png" }) {
			childImageSharp {
				fluid(maxWidth: 350, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;
